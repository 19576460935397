import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo1 from "../img/male1.png";
import logo2 from "../img/male.png";

const Navbar = () => {
  const [logo, setLogo] = useState(logo1);
  const [isReduced, setIsReduced] = useState(false);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const collapseNavbar = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 50) {
        setIsReduced(true);
        setLogo(logo2);
      } else {
        setIsReduced(false);
        setLogo(logo1);
      }
    };

    if (location.pathname === '/') {
      setIsReduced(false);
      setLogo(logo1);
    } else {
      setIsReduced(true);
      setLogo(logo2);
    }

    if (location.pathname === '/') {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [location]);

  return (
    <nav
      className={`navbar navbar-b navbar-expand-md fixed-top ${
        isReduced || location.pathname !== '/' ? "navbar-reduce" : "navbar-trans"
      }`}
      id="mainNav"
    >
      <div className="container">
        <Link className="navbar-brand" to="/" onClick={collapseNavbar}>
          <img 
            src={logo} 
            alt="logo" 
            style={{ 
              maxWidth: location.pathname === '/' ? "300px" : "150px",
              maxHeight: location.pathname === '/' ? "120px" : "55px",
              transition: "all 0.3s ease-in-out"
            }} 
          />
        </Link>
        <button
          className={`navbar-toggler ${!isOpen ? 'collapsed' : ''}`}
          type="button"
          onClick={toggleNavbar}
          aria-expanded={isOpen}
          aria-label="Toggle navigation"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div
          className={`navbar-collapse collapse ${isOpen ? 'show' : ''}`}
          id="navbarDefault"
        >
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/" onClick={collapseNavbar}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about" onClick={collapseNavbar}>
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/experience" onClick={collapseNavbar}>
                Experience
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/skills" onClick={collapseNavbar}>
                Skills
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/portfolio" onClick={collapseNavbar}>
                Projects
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact" onClick={collapseNavbar}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
