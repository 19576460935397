import React from "react";
import myImage from "../img/myImage.png";
import PersonSchema from "../seo/PersonSchema";

class Skills extends React.Component {
  constructor() {
    super();
    this.state = {
      skills1: [
        { id: "HTML5_skill", content: "Web tools: HTML, CSS, Bootstrap, ReactStrap, Sematic UI ", porcentage: "80%", value: "80" },
        { id: "CSS3_skill", content: "Javascript,java,python", porcentage: "75%", value: "75" },
        {
          id: "JavaScript_skill",
          content: "React JS, React hooks, Redux, Redux-form",
          porcentage: "90%",
          value: "90"
        },
        { id: "PHP_skill", content: "Nodejs,expressJS ", porcentage: "70%", value: "70" },
        {
          id: "ReactJS_skill",
          content: "DBMS: MySQL, SQL Server, Oracle",
          porcentage: "80%",
          value: "80"
        },
        {
          id: "Python_skill",
          content: "XML,C",
          porcentage: "75%",
          value: "75"
        },
        {
          id: "VanillaJS_skill",
          content: "Web Services: SOAP,REST",
          porcentage: "85%",
          value: "85"
        },
        {
          id: "Wordpress_skill",
          content: "Spring boot,JWT",
          porcentage: "80%",
          value: "80"
        }
        
      ],

      skills2: [
        { id: "HTML5_skill3dx", content: "Modeling: UML, Merise,Design patter", porcentage: "80%", value: "80" },
        { id: "HTML5_skill3dx", content: "Devops:Jenkins , Docker ,Gitlab CI/CD, Github Actoins", porcentage: "80%", value: "80" },
        { id: "HTML5_skill", content: "3Dexperience,JPO,FMEA, Testability MQL, EKL,BL ", porcentage: "65%", value: "80" },
        {
          id: "JavaScript_skill",
          content: "Temenos T24:Customization&Local development,OFS, BATCH",
          porcentage: "90%",
          value: "90"
        },
      
        {
          id: "Wordpress_skill",
          content: "SAFe Framework, Scrum, Jira, and Confluence Management ",
          porcentage: "80%",
          value: "80"
        },
      
        {
          id: "Wordpress_skill",
          content: "OS:  Windows/Linux/Unix administration",
          porcentage: "80%",
          value: "80"
        },
      
        {
          id: "Wordpress_skill",
          content: "Testing: Selinum ,TestNG, JMeter ",
          porcentage: "80%",
          value: "80"
        },
      
        {
          id: "Wordpress_skill",
          content: "React Native, SonarQube ,GitHub/Git,ESB",
          porcentage: "80%",
          value: "80"
        }



        
      ],
  
  
    };
  }

  render() {
    return (
      <section id="skills" className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
          
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                <div className="col-md-6">
                <h5 style={{color:"orange"}} className="title-left">Skills</h5>
                    <div className="row">
                      <div
                        className="col-sm-6 col-md-5"
                        style={{ margin: "0 auto" }}
                      >
                        <div
                          className="about-img"
                          style={{ textAlign: "center" }}
                        >
                          <img
                            className="img-fluid rounded b-shadow-a"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="skill-mf">
                      {/* <p className="title-s">Skill</p> */}
                      {this.state.skills1.map(skill => {
                        return (
                          <React.Fragment key={skill.id}>
                            <span  >{skill.content}</span>{" "}
                            <span style={{color: "#0078ff"}} className="pull-right">
                              {skill.porcentage}
                            </span>
                            <div className="progress">
                              <div
                               
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: skill.porcentage }}
                                aria-valuenow={skill.value}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div
                        className="col-sm-6 col-md-5"
                        style={{ margin: "0 auto" }}
                      >
                        <div
                          className="about-img"
                          style={{ textAlign: "center" }}
                        >
                          <img
                            className="img-fluid rounded b-shadow-a"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="skill-mf">
                      {/* <p className="title-s">Skill</p> */}
                      <br></br>
                      <br></br>
                      {this.state.skills2.map(skill => {
                        return (
                          <React.Fragment key={skill.id}>
                            <span>{skill.content}</span>{" "}
                            <span style={{color: "#0078ff"}} className="pull-right">
                              {skill.porcentage}
                            </span>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: skill.porcentage }}
                                aria-valuenow={skill.value}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PersonSchema/>
      </section>
    );
  }
}

export default Skills;
