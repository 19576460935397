import React, { useState } from "react";
import {db} from "../firebase/config"
import Swal from 'sweetalert2'
import imageOverlay from "../img/earth.jpg";
import {useForm} from "react-hook-form"
import PersonSchema from "../seo/PersonSchema";
import ContactMeSeo from "../seo/ContactMeSeo";

const Contact =()=> {
  const [name,setName]=useState("");
  const [email,setEmail]=useState("");
  const [subject,setSubject]=useState("");
  const [message,setMessage]=useState("")


  const handleSubmit=(e)=>{
    e.preventDefault();
    db.collection('contactus').add(
      {
        name:name,
        email:email,
        subject:subject,
        message:message
      }
    ).then(
      ()=>{
       
        Swal.fire({
          title: 'Your message has been sent. Thank you!',
          text: " If we have worked together or if you have heard about me, your recommendation on linkedin will be helpful.",
          icon: 'success',
          showCancelButton: true,
          confirmButtonColor: '#6aa5e6',
          cancelButtonColor: '#e6ab63n',
          confirmButtonText: 'Okay,I will do it'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.replace("https://www.linkedin.com/in/zeglami");
          }
        })


      }

    )
    .catch(
      (error)=>{
        alert("This is the end of the internet,please try again!")
      }
    )
  };




    return (
      <section
      style={{backgroundColor:"orange" }}
        className="paralax-mf footer-paralax bg-image sect-mt4 route"
      >
        <ContactMeSeo/>
        <div className="overlay-mf"></div>
        <div style={{backgroundColor:"orange"}} className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="contact-mf">
                <div id="contact" className="box-shadow-full">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="title-box-2">
                        <h5 style={{color:"orange"}} className="title-left">Send A Message</h5>
                      </div>
                      <div>
                        <form
                          onSubmit={handleSubmit}
                          className="contactForm"
                        >
                          <div id="sendmessage">
                            Your message has been sent. Thank you!
                          </div>
                          <div id="errormessage"></div>
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="name"
                                  value={name}
                                  
                                  onChange={(e)=>setName(
                                    e.target.value
                                  ) }
                                  className="form-control"
                                  id="name"
                                  placeholder="Your Name"
                                  data-rule="minlen:4"
                                  data-msg="Please enter at least 4 chars"
                                  required
                                />
                                <div className="validation"></div>
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <input
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  id="email"
                                  value={email}
                                  onChange={(e)=>setEmail(
                                    e.target.value
                                  ) }
                                  placeholder="Your Email"
                                  data-rule="email"
                                  data-msg="Please enter a valid email"
                                  required
                                />
                                <div className="validation"></div>
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="subject"
                                  value={subject}
                                  onChange={(e)=>setSubject(
                                    e.target.value
                                  ) }
                                  id="subject"
                                  placeholder="Subject"
                                  data-rule="minlen:4"
                                  data-msg="Please enter at least 8 chars of subject"
                                  required
                                />
                                <div className="validation"></div>
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <textarea
                                  className="form-control"
                                  name="message"
                                  rows="5"
                                  value={message}
                                  onChange={(e)=>setMessage(
                                    e.target.value
                                  ) }
                                  data-rule="required"
                                  data-msg="Please write something for us"
                                  placeholder="Message"
                                  required
                                ></textarea>
                                <div className="validation"></div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <button
                              style={{backgroundColor:"orange"}}
                                type="submit"
                                className="button button-a button-big button-rouded"
                              >
                                Send Message
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="title-box-2 pt-4 pt-md-0">
                        <h5  style={{color:"orange"}} className="title-left">Get in Touch</h5>
                      </div>
                      <div className="more-info">
                        <h4>Ready to transform an idea to something awesome?</h4>
                        <p className="lead">
                          Whether you want to get in touch, talk about a project
                          collaboration, or just say hi, I'd love to hear from
                          you.
                          <br />
                          Simply fill the from or send me an email.
                        </p>
                        {/* <!-- <ul class="list-ico">
                                <li><span class="ion-ios-location"></span> t</li>
                                <li><span class="ion-ios-telephone"></span> tt</li>
                                <li><span class="ion-email"></span> mail</li>
                                </ul> --> */}
                      </div>
                      <div className="socials">
                        <ul>
                       
                          <li>
                            <a
                              href="https://github.com/zeglami"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="ico-circle">
                                <i className="ion-social-github"></i>
                              </span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/in/zeglami"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="ico-circle">
                                <i className="ion-social-linkedin"></i>
                              </span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.youtube.com/channel/UCKR0DAeuw6ja8UrMF_ptdOA?sub_confirmation=1"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="ico-circle">
                                <i className="ion-social-youtube"></i>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="copyright-box">
                </div>
              </div>
            </div>
          </div>
        </footer>
        <PersonSchema/>
      </section>
    );
  
}

export default Contact;
