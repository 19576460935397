 import firebase from "firebase"

  const firebaseConfig = {
  apiKey: "AIzaSyA0BZ16ZbdWvY4YhI50A5wkP3FZQ7Y4hvs",
  authDomain: "zegitprod.firebaseapp.com",
  projectId: "zegitprod",
  storageBucket: "zegitprod.appspot.com",
  messagingSenderId: "306986893274",
  appId: "1:306986893274:web:20cf0c4b5ad418ccf14ae3",
  measurementId: "G-9HEB3Z3MJS"

   };
   // Initialize Firebase
   const fire=firebase.initializeApp(firebaseConfig);
   var db = firebase.firestore();
   var storage=firebase.storage();
 
   firebase.analytics();
   export {fire,
    db,storage}
