import React from "react";
import "./stars.scss";
import { ReactTyped } from 'react-typed';
import PersonSchema from "../seo/PersonSchema";
import { useNavigate } from 'react-router-dom';

const Intro = () => {
  const navigate = useNavigate();

  return (
    <div id="home" className="intro route bg-image background">
      <div id="stars" />
      <div id="stars2" />
      <div id="stars3" />

      <div className="intro-content display-table">
        <div className="table-cell">
          <div className="container">
            <h1 className="intro-title mb-4">Welcome</h1>
            <p className="intro-subtitle">
              <span className="text-slider-items"></span>
              <strong className="text-slider">
                <ReactTyped
                  strings={[
                    "Front End Developer",
                    "Back End Developer",
                    "Software Engineer",
                  ]}
                  typeSpeed={80}
                  backDelay={1100}
                  backSpeed={30}
                  loop
                />
              </strong>
              <br />
              <p className="pt-3">
                <button
                  className="btn btn-primary btn js-scroll px-4"
                  onClick={() => navigate('/portfolio')}
                >
                  View My Work
                </button>
              </p>
              <strong className="text-slider">
                <ReactTyped
                  strings={[
                    "I speak French",
                    "I speak Arabic",
                    "I speak English",
                  ]}
                  typeSpeed={80}
                  backDelay={1100}
                  backSpeed={30}
                  loop
                />
              </strong>
            </p>
          </div>
        </div>
      </div>
      <PersonSchema />
    </div>
  );
};

export default Intro;
