// PersonSchema.js
//
import React from 'react';

const PersonSchema = () => {
  return (
<script type="application/ld+json">
  {`
    {
      "@context": "http://schema.org",
      "@type": "Person",
      "name": "ZEGLAMI Abdelhamid",
      "jobTitle": "Software Engineer",
      "url": "https://zegit.me",
      "sameAs": [
        "https://linkedin.com/zeglami",
        "https://github.com/zeglami"
      ],
      "alumniOf": {
        "@type": "EducationalOrganization",
        "name": "Ecole Nationale Supérieure de l’Informatique et d’Analyse des Systèmes"
      },
      "memberOf": {
        "@type": "Organization",
        "name": "BCP Securities"
      },
      "worksFor": {
        "@type": "Organization",
        "name": "Banque Populaire"
      },
      "worksFor": {
        "@type": "Organization",
        "name": "BCP Technologies"
      },
      "worksFor": {
        "@type": "Organization",
        "name": "AIRBUS"
      },
      "hasOccupation": [
        {
          "@type": "JobPosting",
          "title": "Ingénieur d'étude et développement",
          "startDate": "2022-02-01",
          "endDate": "2023-10-31",
          "hiringOrganization": {
            "@type": "Organization",
            "name": "AIRBUS"
          },
          "jobLocation": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Paris",
              "addressRegion": "ile de France",
              "addressCountry": "France"
            }
          }
        },
        {
          "@type": "JobPosting",
          "title": "FONCTION",
          "startDate": "2019-09-01",
          "endDate": "2022-01-31",
          "hiringOrganization": {
            "@type": "Organization",
            "name": "BCP Securities/BCP Technologies"
          },
          "jobLocation": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Casaclanca",
              "addressRegion": "Grand Casablanca",
              "addressCountry": "Morocco"
            }
          }
        },
        {
          "@type": "JobPosting",
          "title": "Développeur full stack",
          "startDate": "2019-02-01",
          "endDate": "2019-06-30",
          "hiringOrganization": {
            "@type": "Organization",
            "name": "Banque Centrale Populaire(BCP)/ Banque Centrale Populaire(BCP)"
          },
          "jobLocation": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Toulouse",
              "addressRegion": "France",
              "addressCountry": "France"
            }
          }
        }
      ],
      "hasSkill": [
        "Java",
        "React.js",
        "Spring Boot",
        "JavaScript",
        "HTML",
        "CSS",
        "SQL",
        "Jira",
        "Confluence",
        "GitLab",
        "Maven",
        "Docker",
        "AWS",
        "Agile",
        "Scrum",
        "Safe Frawork",
        "UML",
        "Testing (JUnit, TestNG, Selenium)"
      ]
    }
  `}
</script>

  );
};

export default PersonSchema;
