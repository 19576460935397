import React from "react";
import { Helmet } from "react-helmet";

const ContactMeSeo = () => {
  return (
    <div className="application">
      <Helmet>
        <meta charset="utf-8" />
        <title>ZEGLAMI-Full Stack Web Developer-Contact</title>
        <meta
          name="description"
          content="Contact-Experienced Full Stack Developer proficient in Java Spring Boot, robust backend systems, and dynamic React frontend.Expertise in aerospace, finance, and banking"
        />
        <meta
          name="description"
          content="Contact-Highlighting my experience as a software engineer"
        />
      </Helmet>
    </div>
  );
};

export default ContactMeSeo;
